<template>
    <div class="addInfoItem">
        <van-nav-bar title="录入成绩" left-arrow @click-left="onClickLeft" />
        <!-- <van-picker title="科目" :columns="columns" @confirm="onConfirm" @cancel="onCancel" @change="onChange" /> -->
        <div>
            <van-form @submit="onSubmit" :examInfo="examInfo">
                <van-cell-group inset>
                    <!-- <van-field v-model="subject" name="科目" label="科目" placeholder="科目" :rules="[{ required: true, message: '请填写用户名' }]" /> -->
                    <van-field readonly clickable v-model="examInfo.examDate" name="exemDate" label="日期" placeholder="点击选择" v-on:click="columnsDateShowClick" />

                    <van-field readonly clickable name="examSubject" :value="examInfo.examSubject" label="科目" placeholder="点击选择" v-on:click="columnsSubjectShow"></van-field>
                    <van-field v-model="examInfo.examScore" name="分数" label="分数" type="number" placeholder="分数" :rules="[{ required: true, message: '请填写分数' }]" />
                    <van-field v-model="examInfo.examRank" name="排名" label="排名" type="number" placeholder="排名" :rules="[{ required: true, message: '请填写排名' }]" />
                    <van-field v-model="examInfo.examDesc" name="描述" label="描述" placeholder="描述" />
                </van-cell-group>
                <div style="margin: 16px;">
                    <van-button round block type="primary" native-type="submit">
                        提交
                    </van-button>
                </div>
            </van-form>
        </div>
        <mt-popup v-show="columnsDateShow" class="popup-input-box pa" :overlay="true" position="top" popup-transition="popup-fade">
            <!-- <div class="popup-input-box-cover" @click="columnsDateShow = false"></div> -->
            <van-datetime-picker
                v-model="examInfo.examDate"
                type="date"
                :formatter="formatter"
                title="选择年月日"
                v-on:cancel="onCancelDate"
                v-on:confirm="onConfirmDate"
                :minDate="minDate"
                :max-date="maxDate"
            />
        </mt-popup>
        <!-- <van-overlay :show="columnsDateShow" @click="show = false" /> -->
        <mt-popup v-show="columnssujectShow" position="top">
            <van-picker ref="ppkk" title="科目" show-toolbar :columns="columns" v-on:confirm="onConfirmgoods" v-on:cancel="onCancelSubject" v-on:change="onChangegoods"></van-picker>
        </mt-popup>
    </div>
</template>

<script>
import { addExamInfo } from '@/api/exam.api.js'
import Vue from 'vue'
import { Toast } from 'vant'
Vue.use(Toast)
export default {
    name: 'addInfoItem',
    data() {
        return {
            dialogTitle: 'CES',
            examInfo: {
                userName: '晨曦', //姓名
                examSubject: '', //科目
                examDate: '', //日期
                examScore: 50, //分数
                examRank: 10, //排名
                examDesc: '' //描述
            },
            changeDate: new Date(),
            minDate: new Date(2022, 8, 1),
            maxDate: new Date(2050, 10, 1),
            columnsDateShow: false,
            columnssujectShow: false,
            columns: ['语文', '数学', '英语', '道法', '历史', '语数外', '总分']
        }
    },

    components: {},
    watch: {},
    methods: {
        onClickLeft() {
            history.back()
        },
        onSubmit() {
            addExamInfo(this.examInfo).then(res => {
                if (res.code == 200) {
                    Toast('添加成功')
                    history.back()
                }
                Toast('添加失败')
            })
            console.log(this.examInfo, 'in.........')
        },
        onCancelDate() {
            this.columnsDateShow = false
        },
        onConfirmDate(v) {
            let tmp = this.timeFormat(v)
            console.log(v, 'vvvvvvvvvvvvvv:', tmp)
            this.examInfo.examDate = tmp
            this.columnsDateShow = false
        },
        onConfirmgoods(v) {
            console.log(v, '-----------')
            this.examInfo.examSubject = v
            this.columnssujectShow = false
        },
        columnsDateShowClick() {
            this.columnsDateShow = true
        },
        columnsSubjectShow() {
            this.columnssujectShow = true
        },
        onCancelSubject() {
            this.columnssujectShow = false
        },
        timeFormat(time) {
            // 时间格式化 2019-09-08
            let year = time.getFullYear()
            let month = time.getMonth() + 1
            let day = time.getDate()
            return year + '-' + month + '-' + day
        },
        formatter(type, val) {
            if (type === 'year') {
                return val + '年'
            }
            if (type === 'month') {
                return val + '月'
            }
            if (type === 'day') {
                return val + '日'
            }
            return val
        }
    }
}
</script>

<style lang="less" scoped>
.item-tip {
    color: #999;
    font-size: 12px;
}
.item-time {
    width: 100px;
}
.num-tip {
    padding-left: 80px;
    margin-bottom: 20px;
}
.cer-cover {
    width: 240px;
    height: 155px;
    text-align: center;
    img {
        max-height: 135px;
        max-width: 240px;
        display: block;
    }
}
.popup-input-box-cover {
    height: calc(100vh - 8.8rem);
    background: rgba(0, 0, 0, 0.25);
}
.popup-input-box {
    overflow: hidden;
    width: 100%;
    background: none;
}
</style>
